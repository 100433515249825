import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import CaseStudyCard from "../../components/CaseStudyCard";
import InnerContainer from "../../components/InnerContainer";

const FeaturedContainer = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 0rem 1.5rem 2rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 6rem 5.5rem;
  }
`;

const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CaseStudiesListing = ({ fieldGroupName }) => {
  const allCaseStudies = useStaticQuery(caseStudiesQuery);
  const caseStudies = allCaseStudies.allWpCaseStudy.nodes;
  // console.log(caseStudies);
  return (
    <FeaturedContainer>
      <InnerContainer>
        <ListingContainer>
          {caseStudies.map(caseStudy => (
            <CaseStudyCard key={caseStudy.databaseId} caseStudy={caseStudy} />
          ))}
        </ListingContainer>
      </InnerContainer>
    </FeaturedContainer>
  );
};

const caseStudiesQuery = graphql`
  {
    allWpCaseStudy(sort: { order: ASC, fields: databaseId }) {
      nodes {
        databaseId
        title
        uri
        CaseStudy {
          card {
            title
            ctaText
            description
            logo {
              databaseId
              altText
              localFile {
                extension
                publicURL
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            backgroundColor
            backgroundImageDesktop {
              databaseId
              altText
              localFile {
                extension
                publicURL
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            backgroundImageTablet {
              databaseId
              altText
              localFile {
                extension
                publicURL
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            backgroundImageMobile {
              databaseId
              altText
              localFile {
                extension
                publicURL
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default CaseStudiesListing;
